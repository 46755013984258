
























import { Component, Vue } from 'vue-property-decorator'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import Loader from '@/components/common/Loader.vue'
import LoadStatusMessage from '@/components/common/LoadStatusMessage.vue'
import { Special } from '@/shared/models/specials'

@Component({
  components: {
    WidgetTitle,
    Loader,
    LoadStatusMessage
  }
})
export default class Reviews extends Vue {
  isLoading = true

  get specials(): Array<Special> {
    return this.$store.getters.specials
  }

  mounted(): void {
    if (this.specials.length) {
      this.isLoading = false
    } else {
      this.$store.dispatch('getSpecials').finally(() => {
        this.isLoading = false
      })
    }
  }
}
